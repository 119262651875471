import React, { FC, Fragment } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import AutoplayBgVideo from 'common/AutoplayBgVideo';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Features from './Features';
import { IHomeBannerProps } from './model';

import './HomeBanner.scss';

const HomeBanner: FC<IHomeBannerProps> = ({
  background,
  mobileBackground,
  image,
  mobileImage,
  disclaimer = '',
  description = '',
  descriptionLogo,
  cta = [],
  icon,
  buttonsClassName = '',
  isBigImageMode = false,
  disclaimerOutside,
  isLazyLoading = false,
  isCarouselBackgroundOnly,
  additionalBlockDescription,
  additionalBlockCta,
  additionalBlockImage,
  titleColor,
  isTransparentDisclaimer,
  isCtaOnTheBottomOnMobile,
  isDisclaimerAboveCtaOnMobile,
  isTopImage,
  upsellBanner,
  videoFile,
  topText,
  bgColor,
  ctaBgColor,
  bannerClass,
  features,
  footnote,
  switchMobileBackgroundOnDesktop,
  isRtl,
  textStyle,
}) => {
  const { isDesktop, isNotMobile } = useScreenRecognition();

  const imageSet = {
    mobile: {
      bannerImage: mobileImage?.[0]?.properties,
      bannerBackground: mobileBackground?.[0]?.properties,
    },
    desktop: {
      bannerImage: image?.[0]?.properties,
      bannerBackground: background?.[0]?.properties,
    },
  };
  const backgroundDesktopBreakpoint = switchMobileBackgroundOnDesktop ? isDesktop : isNotMobile;
  const { bannerImage, bannerBackground } = backgroundDesktopBreakpoint
    ? imageSet.desktop
    : imageSet.mobile;
  const additionalImage = additionalBlockImage?.[0]?.properties;
  const isEmptyDisclaimerBlock = !!bannerImage && !disclaimer;
  const hasAdditionalBlock = additionalBlockDescription && additionalBlockCta?.length;
  const hasCta = cta?.length > 0;
  const hasInfoBlock = description || hasCta;
  const showCtaOnTheBottomOnMobile = isCtaOnTheBottomOnMobile && !isDesktop;
  const showDisclaimerAboveCtaOnMobile = isDisclaimerAboveCtaOnMobile && !isDesktop;

  const DisclaimerElement = () =>
    disclaimer || isEmptyDisclaimerBlock ? (
      <DangerouslySetInnerHtml
        className={classNames('home-banner__disclaimer', {
          'home-banner__disclaimer--empty': isEmptyDisclaimerBlock,
          'home-banner__disclaimer--transparent': isTransparentDisclaimer,
          [`${titleColor?.[0]}`]: titleColor?.[0],
          [`${textStyle?.[0]}`]: textStyle?.[0],
        })}
        html={disclaimer || ' '}
      />
    ) : null;

  const CtaElements = () => (
    <div
      className={classNames('home-banner__buttons home-banner__buttons--dark-theme', {
        [`${buttonsClassName}`]: buttonsClassName,
      })}
    >
      {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
        const { name, url, target } = link?.[0] || {};

        return (
          <Fragment key={name}>
            <Button
              to={url}
              ariaLabel={ariaLabel}
              variant={idx === 0 ? 'primary-link' : 'secondary-link'}
              iconSuffix="chevron-right"
              target={target}
              classes={classNames({
                [`btn--primary-link--${ctaBgColor?.label}`]: ctaBgColor && idx === 0,
              })}
            >
              {name}
            </Button>
            {` `}
          </Fragment>
        );
      })}

      {isDesktop && icon?.length ? (
        <div className="home-banner__icon">
          <GatsbyImage
            image={icon[0].properties.image}
            alt={icon[0].properties.imageAlt}
            objectFit="contain"
            className="home-banner__icon-image"
            isLazyLoading={false}
          />
        </div>
      ) : null}
    </div>
  );

  const isDescriptionLogoVisible = isRtl && isDesktop;

  return (
    <div
      className={classNames('home-banner', {
        'home-banner--carousel-bg-only': isCarouselBackgroundOnly,
        'home-banner--additional-block': hasAdditionalBlock,
        'home-banner--top-image-style': isTopImage,
        [`home-banner--${bannerClass}`]: bannerClass,
      })}
    >
      {upsellBanner ? <div className="home-banner__upsell">{upsellBanner}</div> : null}
      <div
        className={classNames('home-banner__background', {
          'home-banner--big-image': isBigImageMode,
          [`home-banner__background--${bgColor?.label}`]: bgColor,
        })}
      >
        <DangerouslySetInnerHtml className="home-banner__prepend" html={topText} />

        {bannerBackground?.image && (
          <GatsbyImage
            image={bannerBackground.image}
            alt={bannerBackground?.imageAlt}
            style={{ position: 'absolute' }}
            className="home-banner__background-img"
            isLazyLoading={isLazyLoading}
            fadeIn={false}
          />
        )}
        {videoFile?.url && <AutoplayBgVideo mainVideo={videoFile.url} />}

        <Container fluid>
          <div
            className={classNames('home-banner__grid', {
              [`${textStyle?.[0]}`]: textStyle?.[0],
            })}
          >
            {hasInfoBlock ? (
              <div className="home-banner__info  home-banner__info--dark-theme">
                {descriptionLogo?.length && !isDescriptionLogoVisible ? (
                  <div className="home-banner__description-logo">
                    <GatsbyImage
                      image={descriptionLogo[0].properties.image}
                      alt={descriptionLogo[0].properties.imageAlt}
                      objectFit="contain"
                      className="home-banner__description-image"
                      isLazyLoading={false}
                      objectPosition="0 50%"
                    />
                  </div>
                ) : null}

                <DangerouslySetInnerHtml
                  className={classNames('home-banner__description', {
                    [`${titleColor?.[0]}`]: titleColor?.[0],
                    [`${textStyle?.[0]}`]: textStyle?.[0],
                  })}
                  html={description}
                />
                {features?.length ? <Features features={features} /> : null}
                {hasCta && !showCtaOnTheBottomOnMobile ? <CtaElements /> : null}
                <DangerouslySetInnerHtml className="home-banner__footnote" html={footnote} />
              </div>
            ) : null}

            {hasAdditionalBlock ? (
              <div className="home-banner__info home-banner__info--additional home-banner__info--dark-theme">
                <div className="home-banner__info--text">
                  <DangerouslySetInnerHtml
                    className="home-banner__description"
                    html={additionalBlockDescription}
                  />

                  {additionalBlockCta.length > 0 && (
                    <div
                      className={classNames('home-banner__buttons', {
                        [`${buttonsClassName}`]: buttonsClassName,
                      })}
                    >
                      {additionalBlockCta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
                        const linkItem = link?.[0];

                        return (
                          <Fragment key={linkItem?.name}>
                            <Button
                              to={linkItem?.url}
                              ariaLabel={ariaLabel}
                              variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                              iconSuffix="chevron-right"
                              target={linkItem?.target}
                            >
                              {linkItem?.name}
                            </Button>
                            {` `}
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>

                {additionalImage?.image ? (
                  <div className="home-banner__info--additional-image">
                    <GatsbyImage
                      image={additionalImage.image}
                      alt={additionalImage?.imageAlt}
                      style={{ position: 'absolute' }}
                      fadeIn={false}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {bannerImage?.image ? (
              <div className="home-banner__image">
                {descriptionLogo?.length && isDescriptionLogoVisible ? (
                  <div className="home-banner__description-logo">
                    <GatsbyImage
                      image={descriptionLogo[0].properties.image}
                      alt={descriptionLogo[0].properties.imageAlt}
                      objectFit="contain"
                      className="home-banner__description-image"
                      isLazyLoading={false}
                      objectPosition="0 50%"
                    />
                  </div>
                ) : null}

                <GatsbyImage
                  image={bannerImage.image}
                  alt={bannerImage.imageAlt}
                  objectFit="contain"
                  className="home-banner__image-img"
                  isLazyLoading={isLazyLoading}
                  fadeIn={false}
                />
              </div>
            ) : null}
            {showDisclaimerAboveCtaOnMobile ? <DisclaimerElement /> : null}
            {hasCta && showCtaOnTheBottomOnMobile ? <CtaElements /> : null}
          </div>
          {!showDisclaimerAboveCtaOnMobile && (!disclaimerOutside || isDesktop) ? (
            <DisclaimerElement />
          ) : null}
        </Container>
        {isDisclaimerAboveCtaOnMobile || !disclaimerOutside || isDesktop ? null : (
          <DisclaimerElement />
        )}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHomeBanner on THomeBanner {
    properties {
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 992, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 650, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 650, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      descriptionLogo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 272, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      textStyle
      description
      disclaimer
      cta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      icon {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 360, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      isBigImageMode
      disclaimerOutside
      isCarouselBackgroundOnly
      additionalBlockDescription
      upsellBanner
      videoFile {
        url
        fallbackUrl
      }
      isTopImage
      additionalBlockCta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      additionalBlockImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 150, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      titleColor
      isTransparentDisclaimer
      isCtaOnTheBottomOnMobile
      isDisclaimerAboveCtaOnMobile
      topText
      bgColor {
        label
      }
      ctaBgColor {
        label
      }
      bannerClass
      features {
        properties {
          text
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      switchMobileBackgroundOnDesktop
      footnote
    }
    structure
  }
`;

export default HomeBanner;
