import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IVideoProps } from '@shared/types/umbraco/compositions/common';
import Button from 'common/Button';
import { isBrowser } from 'utils/browser';
import { getId } from './helpers';
import './Video.scss';

const Video: FC<IVideoProps> = ({
  previewImage: image,
  description = '',
  time = '',
  youtubeLink = '',
  vimeoLink = '',
  className = '',
  btnAriaLabel = '',
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const videoId = useMemo(() => (youtubeLink ? getId(youtubeLink) : ''), [youtubeLink]);
  const playVideo = useCallback(() => setIsActive(true), []);
  const previewImage = image?.[0]?.properties;

  useEffect(() => {
    if (isBrowser()) {
      const el = document.getElementById('__YOUTUBE_SCRIPT__');
      if (!el) {
        const script = document.createElement('script');
        script.id = '__YOUTUBE_SCRIPT__';
        script.async = true;
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);
      }
    }
  }, []);

  return (
    <Container fluid className={className}>
      <div className="video">
        <div className="embed-responsive embed-responsive-16by9">
          {isActive || !previewImage ? (
            youtubeLink ? (
              <iframe
                loading="lazy"
                width="640"
                height="360"
                src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0${
                  previewImage ? '&autoplay=1' : ''
                }&modestbranding=1&enablejsapi=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <iframe
                loading="lazy"
                title="video frame"
                src={`https://player.vimeo.com/video/${vimeoLink}?${
                  previewImage ? 'autoplay=1' : ''
                }`}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            )
          ) : (
            <div className="video__preview">
              <GatsbyImage
                image={previewImage.image}
                alt={previewImage.imageAlt}
                style={{ position: 'absolute' }}
              />
              {time ? (
                <div className="video__time-wrapper">
                  <div className="video__time-background">
                    <div className="video__time">{time}</div>
                  </div>
                </div>
              ) : null}
              <Button
                variant="icon"
                iconPrefix="play"
                classes="video__play-icon"
                onClick={playVideo}
                ariaLabel={btnAriaLabel}
              />
            </div>
          )}
        </div>
        {description ? (
          <DangerouslySetInnerHtml className="video__description" html={description} />
        ) : null}
      </div>
    </Container>
  );
};
export default Video;

export const query = graphql`
  fragment FragmentVideo on TVideoProps {
    youtubeLink
    vimeoLink
    image {
      properties {
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageAlt
      }
    }
    btnAriaLabel
    time
    description
  }

  fragment FragmentVideoProperties on TVideo {
    structure
    properties {
      youtubeLink
      vimeoLink
      previewImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      btnAriaLabel
      time
      description
    }
  }
`;
